/* General styles */
body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #20232a;
}

.App{
  flex: 1;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #20232a;
  color: white;
  box-sizing: border-box;
  width: 100%;
}


/* Main content */
.chat-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  /* background-color: #f7f8fc; */
  /* border-top: 1px solid #e0e0e0; */
}

.chat-input-wrapper {
  display: flex;
  align-items: center;
  /* background-color: #ffffff; */
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.file-input {
  display: none;
}

.file-label {
  font-size: 1.2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  color: #888;
}

.chat-input {
  flex-grow: 1;
  border: none;
  outline: none;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  color: #333;
}

/* .chat-input::placeholder {
  color: #aaa;
} */

.send-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #555;
  margin-left: 0.5rem;
  cursor: pointer;
}

.send-button:hover {
  color: #007bff;
}


.main-content.collapsed {
  margin-left: 80px;
  width: calc(100% - 80px);
}
.home-row{
  display: flex;
  justify-content: flex-end;
  background-color: #282c34;
}
.welcome-container{
  display: grid;
  place-items: center;
  background-color: #282c34;
}
.welcome{
  width: 30%; /* Set width to 30% */
  text-align: center;
  padding: 20px;
  background-color: #333; /* Dark gray background */
  color: #fff; /* White text */
  border-radius: 10px;
}
/* Header */
.App-header {
  background-color: #282c34;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  /* min-height: calc(100vh - 130px);  */
}

/* Footer */
.App-footer {
  background-color: #20232a;
  color: white;
  text-align: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  bottom: 0;
}

.App-footer p {
  margin: 0;
  font-size: 14px;
  margin-bottom: 0;
}

/* Fix for footer at the bottom */
html, body {
  height: 100%;
}

/* Container for left and right sections */
.container {
  display: grid;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex: 0;
  padding: 0;
}

.instruction {
 padding: 10px;
 text-align: center;
 padding-bottom: 30px;
}

.left-section, .right-section {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.left-section {
  flex: 0 0 30%;
  background-color: #3b3f46;
  border-radius: 8px;
  margin-right: 10px;
  justify-content: space-around;
}
.section-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal-width columns */
  gap: 20px; /* Space between items */
}

.section-main {
  flex: 0 0 30%;
  background-color: #3b3f46;
  border-radius: 8px;
  margin-right: 10px;
  justify-content: space-around;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
}

.section-child-desc {
  font-size: 14px;             /* Smaller font for description text */
  color: #888;                 /* Light grey color for secondary text */
  margin-top: 5px;             /* Add space between the main title and description */
  line-height: 1.5;            /* Improve readability by adding line height */
  padding-left: 20px;          /* Indent the description slightly */
  border-left: 2px solid #61dafb; /* Optional: Left border to highlight the section */
  margin-bottom: 10px;         /* Space between sections */
}

.right-section {
  flex: 1;
  background-color: #3b3f46;
  border-radius: 8px;
  justify-content: space-between;
  padding: 0;
}

.upload-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 8px;
  padding: 20px;
  background-color: #4b5058;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.upload-section:hover {
  background-color: #5c626b;
}

.upload-label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.upload-icon {
  font-size: 48px;
  margin-bottom: 10px;
}

.upload-input {
  display: none;
}

.upload-text {
  font-size: 16px;
  margin-top: 5px;
}

.input-field {
  margin-bottom: 20px;
  text-align: left;
}

.input-field input {
  width: 100%;
  padding: 12px 10px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  box-sizing: border-box;
  background-color: #40444b;
  color: white;
}

.input-field input::placeholder {
  color: #b0b3b8;
}

.generate-button {
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: center;
}

.generate-button:disabled {
  background-color: #4da7cf;
  cursor: not-allowed;
}

.generate-button:hover:not(:disabled) {
  background-color: #21a1f1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.overlay.active {
  display: flex;
}

.loading-spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #61dafb;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.result {
  background-color: #40444b;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
  text-align: left;
  color: white;
  overflow-y: auto;
  max-height: 70vh;
  box-sizing: border-box;
}

.reset-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.reset-button:hover {
  background-color: #21a1f1;
}

/* Responsive Design */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
  }

  .left-section {
    flex: 0 0 30%;
    margin-right: 10px;
  }

  .right-section {
    flex: 1;
  }
}

/* Chat Container */
.chat-container {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  background-color: #3b3f46;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
}

/* Chat History */
.chat-history {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.chat-message {
  margin-bottom: 10px;
}

.chat-message strong {
  color: #61dafb;
}

/* Chat Input */
.chat-input-container {
  display: flex;
  align-items: center;
}

.chat-input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: none;
  background-color: #40444b;
  color: white;
  margin-right: 10px;
}

.chat-input::placeholder {
  color: #b0b3b8;
}

.chat-submit-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #61dafb;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.chat-submit-button:hover {
  background-color: #21a1f1;
}
